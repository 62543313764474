import Swal from 'sweetalert2';
import './AlertEmpty.scss';
import { extractSubdomain } from 'helpers/comman';
import { cricket } from 'routes/subdomains';

type IAlertProps = {
  title?: any;
  message?: any;
  button?: any;
  url?: any;
};

const AlertEmpty = ({ title, message, button, url }: IAlertProps) => {
  const subdomain = extractSubdomain();
  Swal.fire({
    title: title,
    html: message,
    customClass: {
      popup: 'popup-class',
      cancelButton: 'cancel-button-class',
      htmlContainer: 'html-class',
      title: subdomain === cricket ? 'title-class cricket-title-class' : 'title-class',
      confirmButton: subdomain === cricket ? 'confirm-button-class cricket-confirm-button-class' : 'confirm-button-class',
      closeButton: subdomain === cricket ? 'close-button-class cricket-close-button-class' : 'close-button-class',
    },
    showCloseButton: true,
    showConfirmButton: true,
    showCancelButton: false,
    confirmButtonText: button,
    cancelButtonText: 'cancel',
    cancelButtonColor: '#D20A0A',
  }).then((result) => {
    if (result.isConfirmed && url) {
      window.location.href = `${window.location.origin}${url}`;
    }
  });
};

export default AlertEmpty;
