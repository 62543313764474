import dayjs from 'dayjs';

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractCampaignName = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const currentHostname = window.location.hostname;
  const hostnameParts = currentHostname.split('.');

  let campaignName = null;

  if (currentHostname !== 'localhost' && hostnameParts.length) {
    campaignName = hostnameParts[0];
  } else {
    campaignName = searchParams.get('subdomain');
  }

  return campaignName;
};

export const dateValidator = (startDate: any, endDate: any) => {
  return dayjs(startDate).isBefore(dayjs(endDate)); // Checks if end date is higher than start date
};

export const hideScript = () => {
  // Use querySelectorAll to select all elements with the ID 'usercentrics-root'
  const scripts = document.querySelectorAll('#usercentrics-root');
  if (scripts.length > 0) {
    scripts.forEach((script: any) => {
      // Hide each element by setting display to 'none'
      script.style.display = 'none';
    });
  }

  // Change the meta title
  document.title = 'Diageo';

  // Change the meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', 'This is a Diageo. Fill it out to help us improve our services.');
  } else {
    // If the meta description doesn't exist, create it
    const newMetaDescription = document.createElement('meta');
    newMetaDescription.name = 'description';
    newMetaDescription.content = 'This is a Diageo. Fill it out to help us improve our services.';
    document.head.appendChild(newMetaDescription);
  }
};

export function extractSubdomain() {
  const isLocalhost = window.location.hostname === 'localhost';

  if (isLocalhost) {
    const storedSubdomain = localStorage.getItem('subdomain');
    const urlParams = new URLSearchParams(window.location.search);
    const subdomainParam = urlParams.get('subdomain');

    // Check if the stored subdomain matches the current subdomain
    if (storedSubdomain) {
      if (subdomainParam && subdomainParam !== storedSubdomain) {
        // Update localStorage if the subdomain has changed
        localStorage.setItem('subdomain', subdomainParam);
        return subdomainParam;
      }
      return storedSubdomain;
    }

    // Store the subdomain in localStorage if it’s not stored yet
    if (subdomainParam) {
      localStorage.setItem('subdomain', subdomainParam);
      return subdomainParam;
    }
  } else {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length > 2) {
      const currentSubdomain = parts[0];
      const storedSubdomain = localStorage.getItem('subdomain');

      // Check if the stored subdomain matches the current subdomain
      if (storedSubdomain) {
        if (currentSubdomain !== storedSubdomain) {
          localStorage.setItem('subdomain', currentSubdomain); // Update stored subdomain if different
        }
        return currentSubdomain;
      }

      // Store the current subdomain if it’s not stored yet
      localStorage.setItem('subdomain', currentSubdomain);
      return currentSubdomain;
    }
  }

  // Default if no subdomain is found
  return null;
}

// Function to auto-format phone number to US format
export const formatPhoneNumber = (value: any) => {
  // Remove all non-numeric characters
  const cleaned = value.replace(/\D/g, '');

  // Format to US pattern (e.g., (123) 456-7890)
  const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (!match) return value;

  const formatted = [match[1] && `(${match[1]}`, match[2] && `) ${match[2]}`, match[3] && `-${match[3]}`]
    .filter(Boolean)
    .join('');

  return formatted;
};
