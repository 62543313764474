import { Dispatch } from 'redux';

// types
import { IAuthActionTypes, INewUserActionTypes } from 'models/IAuthState';
import { IAppActionTypes } from 'models/IAppState';
import { IHistory } from 'models/ICommon';

// services
import authService from 'services/authService';
import httpRequest from 'services/httpRequest';

// configs
import { API_ENDPOINT } from 'apis/common.api';

import { handleScrollToTop } from 'utils/ScrollTop';
import { PATH_NAME } from 'configs/pathName';
import { USER_ROLE } from 'configs/userRole';
import AlertEmpty from 'components/Modals/AlertEmpty';
import Swal from 'sweetalert2';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const enum API {
  CREATE_USER = '/user/',
  PAYMENT_CHECKOUT = '/payment/checkout/',
  ADMIN_LOGIN = '/user/login',
  USER_LOGIN = '/customers/auth_customer_user',
  GET_PROFILE_DETAILS = '/user/profile',
  GET_SUBSCRITPIONS = '/payment/subscriptions',
  RESET_PASSWORD_ADMIN = '/user/password-reset',
  RESET_PASSWORD_CUSTOMER = '/customers/password-reset',
  UPDATE_PASSWORD = '/user/password-update',
  VERIFYTOKEN = '/user/verify_two_fa',
  RESENDTOKEN = '/user/resend_code',
  LOGINWITHUSERID = '/customers/auth_customer_user',
  REDEEMREWARDS = '/redemption',
}

interface UserDataPayload {
  user: string;
  role: string;
  code: string;
}

// Define the action type for your setUserData action
interface SetUserDataAction {
  type: typeof IAuthActionTypes.SILENT_LOGIN;
  payload: UserDataPayload;
}
// const token = authService.getAccessToken();

export const login = (username: string, roleUser: string, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.LOGIN_REQUEST });

  const { user, role } = await authService.loginWithAuth0(username, roleUser, '', '', '', '');

  if (role === USER_ROLE.ADMIN) {
    dispatch({
      type: IAuthActionTypes.TWOFA_REQUEST,
      payload: { user, role },
    });
  } else {
    const { user, role } = await authService.loginWithAuth0(username, roleUser, '', '', '', '');
    dispatch({
      type: IAuthActionTypes.LOGIN_SUCCESS,
      payload: { user, role },
    });
    history.push(PATH_NAME.ROOT);
  }
};

export const loginCustomerUser = (id: string, history: IHistory, domain: any) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: IAuthActionTypes.LOGIN_CUSTOMER_REQUEST });
    const { data } = await axios.post(`${API_ENDPOINT}${API.LOGINWITHUSERID}`, { id, campaign: domain });

    if (data.status) {
      const user = await authService.loginWithCustomerUserAuth0(
        data.data[0].user,
        data.data[0].accessToken,
        data.data[0].camapign,
        data.data[0].customer,
      );
      dispatch({
        type: IAuthActionTypes.LOGIN_CUSTOMER_SUCCESS,
        payload: { user },
      });
      authService.setSession('accessToken', data.data[0].accessToken);
    } else {
      dispatch({
        type: IAuthActionTypes.LOGIN_CUSTOMER_FAILURE,
        payload: { user: data },
      });
      history.push(PATH_NAME.ROOT);
    }
  } catch (error: any) {
    AlertEmpty({ title: 'Login Failed', message: error.response.data.message || 'Please try again later', button: 'Okay' });
    authService.logOut();
    dispatch({ type: IAuthActionTypes.LOGOUT });
    dispatch({ type: IAppActionTypes.LOGOUT });
    handleScrollToTop();
  }
};

export const loginAdmin = (email: string, password: string, history: IHistory) => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.ADMIN_LOGIN}`, { email, password });
    if (data?.status == true) {
      dispatch({
        type: IAuthActionTypes.TWOFA_REQUEST,
        payload: { admin: data.data[0] },
      });
      history.push(PATH_NAME.DASHBOARD);
    } else {
      AlertEmpty({
        title: 'Invalid Credentials',
        message: data?.message,
        button: 'OKAY',
      });
    }
  } catch (error: any) {
    AlertEmpty({ title: 'Login Failed', message: error.message, button: 'Okay' });
    dispatch({
      type: IAuthActionTypes.LOGIN_FAILURE,
      payload: {
        error: 'An error occurred during login. Please try again later.', // Provide an error message
      },
    });
  }
};

export const verifyTwoFaCode = (body: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.VERIFYTOKEN}`, body);
    if (data?.status == true) {
      const { user, role, code } = await authService.loginWithAuth0(
        body.email,
        data.data[0].user_role,
        data.code,
        data.data[0].accessToken,
        data.permission,
        data.data[0].refreshtoken,
      );
      dispatch({
        type: IAuthActionTypes.TWOFA_SUCCESS,
        payload: { user, role, code },
      });
      history.push(PATH_NAME.DASHBOARD);
    } else {
      AlertEmpty({
        title: 'Invalid Verification Code',
        message: data?.message,
        button: 'OKAY',
      });
      // Handle invalid credentials
      dispatch({
        type: IAuthActionTypes.TWOFA_FAILURE,
        payload: {},
      });
    }
  } catch (error: any) {
    AlertEmpty({ title: 'Login Failed', message: error.message, button: 'Okay' });
    dispatch({
      type: IAuthActionTypes.LOGIN_FAILURE,
      payload: {
        error: 'An error occurred during login. Please try again later.', // Provide an error message
      },
    });
  }
};

export const loginUser =
  (email: string, password: string, history: IHistory, campaign: any) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: IAuthActionTypes.LOGIN_CUSTOMER_REQUEST });
    try {
      const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.USER_LOGIN}`, { email, password, campaign: campaign });
      if (data.status) {
        const user = await authService.loginWithCustomerUserAuth0(
          data.data[0].user,
          data.data[0].accessToken,
          data.data[0].camapign,
          data.data[0].customer,
        );
        dispatch({
          type: IAuthActionTypes.LOGIN_CUSTOMER_SUCCESS,
          payload: { user },
        });
        authService.setSession('accessToken', data.data[0].accessToken);
      } else {
        dispatch({
          type: IAuthActionTypes.LOGIN_CUSTOMER_FAILURE,
          payload: { user: null },
        });
        history.push(PATH_NAME.ROOT);
      }
    } catch (error: any) {
      AlertEmpty({ title: 'Login Failed', message: error.response.data.message, button: 'Okay' });
      authService.logOut();
      dispatch({ type: IAuthActionTypes.LOGOUT });
      dispatch({ type: IAppActionTypes.LOGOUT });
      handleScrollToTop();
    }
  };

export const resend2FACode = (body: any) => async (dispatch: Dispatch<any>) => {
  try {
    const response = await httpRequest.postReq(`${API_ENDPOINT}${API.RESENDTOKEN}`, body);
    if (response.data.status === true) {
      dispatch({
        type: IAuthActionTypes.RESEND_TWOFA_REQUEST,
        payload: { sent: true },
      });
      toast.success('Verification code sent.');
    } else {
      AlertEmpty({
        title: 'Unable to send verification code!',
        message: 'Please try agin later!',
        button: 'OKAY',
      });
      // Dispatch an action to handle the API error state if needed
      dispatch({
        type: IAuthActionTypes.RESEND_TWOFA_FAILURE,
        payload: {},
      });
    }
  } catch (error: any) {
    // Handle any network or request-related errors here
    AlertEmpty({
      title: 'Unable to send verification code!',
      message: 'Please try agin later!',
      button: 'OKAY',
    });
    // Dispatch an action to handle the error state if needed
    dispatch({
      type: IAuthActionTypes.RESEND_TWOFA_FAILURE,
      payload: {},
    });
  }
};

export const cancel2FAcode = (body: any) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: IAuthActionTypes.TWOFA_CLOSE,
    payload: {},
  });
};

// export const loginAdmin = (body: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
//   const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.VERIFYTOKEN}`, body);
//   if (data?.status == true) {
//     const { user, role, code } = await authService.loginWithAuth0(body.email, data.role, data.code, data.data[0].accessToken);
//     if (data) {
//       dispatch({
//         type: IAuthActionTypes.TWOFA_SUCCESS,
//         payload: { user, role, code },
//       });
//     }
//   } else {
//     console.log('Invalid Verification Code');
//     // AlertEmpty({
//     //   title: data?.message,
//     //   message: 'Invalid Verification Code',
//     //   button: 'OKAY',
//     // });
//     // Handle invalid credentials
//     dispatch({
//       type: IAuthActionTypes.TWOFA_FAILURE,
//       payload: {},
//     });
//   }
// };

export const resetPasswordCustomer = (email: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.RESET_PASSWORD_REQUEST });
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.RESET_PASSWORD_CUSTOMER}`, email);
    dispatch({
      type: IAuthActionTypes.RESET_PASSWORD,
    });
    if (data?.status === true) {
      dispatch({ type: IAuthActionTypes.LOGOUT });
      history.push(PATH_NAME.ROOT);
      toast.success('Reset link sent succesfully');
    } else {
      if (data?.message) {
        toast.error(data.message);
        return;
      }
      toast.error('Some Error Occured');
    }
  } catch (error: any) {
    if (error?.response?.data?.message[0]) {
      toast.error(error.response.data.message[0]);
      return;
    }
    toast.error('Invalid Email Id');
  }
};
export const resetPasswordAdmin = (email: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.RESET_PASSWORD_REQUEST });
  try {
    const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.RESET_PASSWORD_ADMIN}`, email);
    dispatch({
      type: IAuthActionTypes.RESET_PASSWORD,
    });
    if (data?.status === true) {
      history.push(PATH_NAME.ROOT);
      toast.success('Mail Sent Succesfully');
    } else {
      if (data?.message) {
        toast.error(data?.message);
        return;
      }
      toast.error('Some Error Occured');
    }
  } catch (error: any) {
    if (error?.response?.data?.message[0]) {
      toast.error(error.response.data.message[0]);
      return;
    }
    toast.error('Invalid Email Id');
  }
};

export const updateUserProfile = (formData: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE });
  try {
    const { data } = await httpRequest.patchReq(`${process.env.REACT_APP_ENDPOINT_URL}/customers/users/${formData.id}`, formData);
    if (data?.status === true) {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
      AlertEmpty({
        title: 'Updated Successfully',
        message: 'Your profile has been updated.',
        button: 'OKAY',
      });
    } else {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
      });
      AlertEmpty({
        title: data.message,
        message: 'Please try again later',
        button: 'OKAY',
      });
    }
  } catch (error) {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
    });
    console.error('Error updating user profile:', error);
  }
};

export const updateUserData = (formData: any) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE });
  try {
    const { data } = await httpRequest.getReq(`${process.env.REACT_APP_ENDPOINT_URL}/customers/user/${formData}`);
    if (data?.status === true) {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
      });
    }
  } catch (error) {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE,
    });
    console.error('Error updating user profile:', error);
  }
};

export const updatePassword = (body: string, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.UPDATE_PASSWORD_REQUEST });
  const { data } = await httpRequest.postReq(`${API_ENDPOINT}${API.UPDATE_PASSWORD}`, body);
  dispatch({
    type: IAuthActionTypes.UPDATE_PASSWORD,
  });
  if (data?.status === true) {
    history.push(PATH_NAME.LOGIN);
  }
};

export const logout = (isAdmin?: boolean, history?: IHistory) => (dispatch: Dispatch<any>) => {
  authService.logOut();
  dispatch({ type: IAuthActionTypes.LOGOUT });
  dispatch({ type: IAppActionTypes.LOGOUT });
  handleScrollToTop();
  if (isAdmin) {
    history?.push(PATH_NAME.ADMIN_LOGIN);
  }
  AlertEmpty({ title: 'Successfully logged out', message: 'You are now logged out of your account.', button: 'Okay' });
};

export const logoutSilent = (isAdmin?: boolean, history?: IHistory) => (dispatch: Dispatch<any>) => {
  authService.logOut();
  dispatch({ type: IAuthActionTypes.LOGOUT });
  dispatch({ type: IAppActionTypes.LOGOUT });
  handleScrollToTop();
  if (isAdmin) {
    history?.push(PATH_NAME.ADMIN_LOGIN);
  }
};

export const sessionExpired = () => (dispatch: Dispatch<any>) => {
  authService.logOut();
  dispatch({ type: IAuthActionTypes.LOGOUT });
  dispatch({ type: IAppActionTypes.LOGOUT });
  handleScrollToTop();
  AlertEmpty({
    title: 'Session Expired',
    message: 'Your session has expired. Please log in again.',
    button: 'Okay',
    url: PATH_NAME.ADMIN_LOGIN,
  });
  // setTimeout(() => {
  //   window.location.href = PATH_NAME.ADMIN_LOGIN;
  // }, 3000);
};

export const setUserData =
  (user: string, role: string, code: string) =>
  (
    dispatch: Dispatch<SetUserDataAction>, // Type dispatch with SetUserDataAction
  ) => {
    dispatch({
      type: IAuthActionTypes.SILENT_LOGIN,
      payload: { user, role, code },
    });
  };
export const handleSignupModal = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.OPEN_SIGNUP_MODAL, payload: { open: open } });
};

export const handleLoginModal = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.OPEN_LOGIN_MODAL, payload: { open: open } });
};

export const completeUserProfile = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: INewUserActionTypes.SET_USER_PROFILE_REQUEST,
  });
  try {
    const response = await httpRequest.postReq(`${process.env.REACT_APP_ENDPOINT_URL}/customers/users`, data);
    if (response.data.status) {
      dispatch({
        type: INewUserActionTypes.SET_USER_PROFILE_SUCCESS,
        payload: response.data.data,
      });
      Swal.fire('Completed!', 'Your profile is completed', 'success');
    } else {
      Swal.fire('Error!', response.data.message, 'warning');
    }
  } catch (error) {
    console.error('Error:', error);
    dispatch({
      type: INewUserActionTypes.SET_USER_PROFILE_FAILURE,
    });
    // You can also dispatch an error action here if needed.
  }
};

export const completeUserProfileCricket = (data: any) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: INewUserActionTypes.SET_USER_PROFILE_REQUEST,
  });
  try {
    const response = await httpRequest.postReq(`${process.env.REACT_APP_ENDPOINT_URL}/customers/signup_user`, data);
    if (response.data.status) {
      dispatch({
        type: INewUserActionTypes.SET_USER_PROFILE_SUCCESS,
        payload: {
          ...response.data.data[0].user,
          is_new: false,
        },
      });

      dispatch({ type: IAuthActionTypes.LOGIN_CUSTOMER_REQUEST });
      if (response.data.status) {
        const user = await authService.loginWithCustomerUserAuth0(
          response.data.data[0].user,
          response.data.data[0].accessToken,
          response.data.data[0].camapign,
          response.data.data[0].customer,
        );
        dispatch({
          type: IAuthActionTypes.LOGIN_CUSTOMER_SUCCESS,
          payload: { ...user, user: user, is_new: false },
        });
        authService.setSession('accessToken', response.data.data[0].accessToken);
        data.history.push(PATH_NAME.CRICKET_REWARDLIST);
      } else {
        dispatch({
          type: IAuthActionTypes.LOGIN_CUSTOMER_FAILURE,
          payload: { user: data },
        });
        data.history.push(PATH_NAME.ROOT);
      }
      AlertEmpty({ title: 'Completed', message: 'Your profile is completed', button: 'Okay' });
    } else {
      AlertEmpty({
        title: response.data.message,
        message: 'You already have an account with us. Please log in instead.',
        button: 'Okay',
      });
      dispatch({
        type: IAuthActionTypes.LOGIN_CUSTOMER_FAILURE,
        payload: { user: data },
      });
    }
  } catch (error) {
    dispatch({
      type: INewUserActionTypes.SET_USER_PROFILE_FAILURE,
    });
    AlertEmpty({ title: 'Something went wrong!', message: 'Please try again later.  ', button: 'Okay' });
    // You can also dispatch an error action here if needed.
  }
};

export const handleCartList = (open: boolean) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_CART_OPEN, payload: { open: open } });
};

export const selectOffer = (offer: any) => async (dispatch: Dispatch<any>) => {
  // Assuming you have some asynchronous operation here, like an API call
  try {
    // Dispatch a synchronous action once the asynchronous operation is complete
    dispatch({
      type: 'SELECT_OFFER',
      payload: offer,
    });
  } catch (error) {
    // Handle errors
    dispatch({
      type: 'SELECT_OFFER_FAILURE',
      payload: error,
    });
  }
};
export const addToCart = (item: any, remainingBalance: number, offer_id: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_ADD_TO_CART });

  const itemCredits = item.amount; // Replace 'amount' with your item's credit property
  if (remainingBalance - itemCredits < 0) {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_ADD_TO_CART_FAILURE,
      payload: 'Not enough credits to add this item to the cart.',
    });
    AlertEmpty({ title: 'Not Enough Credit', message: 'Not enough credits to add this item to the cart.', button: 'Okay' });
  } else {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_ADD_TO_CART_SUCCESS,
      payload: item,
      offer_id,
    });
  }
};

export const removeFromCart = (unique_id: any) => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART });
  dispatch({
    type: IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART_SUCCESS,
    payload: unique_id,
  });
};

export const clearAllCartItem = () => (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_CLEAR_CART });
  dispatch({
    type: IAuthActionTypes.SET_REQUEST_CLEAR_CART_SUCCESS,
  });
};

export const redeemRewards = (item: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAuthActionTypes.SET_REQUEST_CHECKOUT });

  if (item) {
    const response = await httpRequest.postReq(`${API_ENDPOINT}${API.REDEEMREWARDS}`, item);
    if (response.data.status) {
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_CHECKOUT_SUCCESS,
        payload: response.data.data,
      });
      history.push(PATH_NAME.CHECKOUT);
      AlertEmpty({ title: 'Completed!', message: 'Reward Redeemed Sucessfully!', button: 'Okay' });
    } else {
      AlertEmpty({ title: response.data.message, message: response.data.message, button: 'Okay' });
      dispatch({
        type: IAuthActionTypes.SET_REQUEST_CHECKOUT_FAILURE,
        payload: response.data.data,
      });
    }
  } else {
    dispatch({
      type: IAuthActionTypes.SET_REQUEST_CHECKOUT_FAILURE,
    });
  }
};
