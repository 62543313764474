import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PATH_NAME } from 'configs/pathName';
import { IRoutes } from '../models/IRoutes';
import NavLayout, { EmptyNavLayout } from 'layouts/NavLayouts/NavLayout';
import { USER_ROLE } from 'configs/userRole';
import AdminAccess from 'guards/AccessGuard/AdminAccess';
import RoleRoute from './RoleRoute';
import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import HomeSkeleton from 'layouts/SkeletonLoader/SkeletonLoader';
import AdminLayoutDecider from 'layouts/AdminLayoutDecider/AdminLayoutDecider';
import { PERMISSION_MODULE, PERMISSION_TYPE } from 'configs/permission';
import PermissionAccess from 'guards/AccessGuard/PermissionAccess';

// Import Users routes
const Home = lazy(() => import('pages/Home/Home'));
const CommingSoon = lazy(() => import('components/CommingSoon/CommingSoon'));
const Login = lazy(() => import('pages/Login/Login'));
const AdminLogin = lazy(() => import('pages/AdminLogin'));
const Cart = lazy(() => import('pages/Cart'));
const Checkout = lazy(() => import('pages/Checkout'));
const RewardsList = lazy(() => import('pages/Rewards'));
const RewardDetails = lazy(() => import('pages/Rewards/RewardDetails'));
const RewardListsId = lazy(() => import('pages/Rewards/RewardListsId'));
const TermsCondition = lazy(() => import('pages/Terms-condition'));
const PrivacyPolicy = lazy(() => import('pages/Privacy-policy'));
const Faq = lazy(() => import('pages/Faq'));
const ContactUs = lazy(() => import('pages/ContactUs'));

// Import dashboard routes
const Dashboard = lazy(() => import('pages/Dashboard'));
const customers = lazy(() => import('pages/Dashboard/Customers'));
const AddCustomer = lazy(() => import('pages/Dashboard/Customers/AddCustomer'));
const UpdateCustomer = lazy(() => import('pages/Dashboard/Customers/UpdateCustomer'));
const Campaigns = lazy(() => import('pages/Dashboard/Campaigns'));
const AddCampaign = lazy(() => import('pages/Dashboard/Campaigns/AddCampaign'));
const UpdateCampaign = lazy(() => import('pages/Dashboard/Campaigns/UpdateCampaign'));
const Rewards = lazy(() => import('pages/Dashboard/Rewards'));
const UpdateRewards = lazy(() => import('pages/Dashboard/Rewards/UpdateRewards'));
const SetRewards = lazy(() => import('pages/Dashboard/Campaigns/SetReward'));
const RewardCategory = lazy(() => import('pages/Dashboard/RewardCategory'));
const UpdateRewardCategory = lazy(() => import('pages/Dashboard/RewardCategory/UpdateRewards'));
const AddRewardCategory = lazy(() => import('pages/Dashboard/RewardCategory/AddCategory'));
const Profile = lazy(() => import('pages/MyAccount/Profile'));
const CustomerUsers = lazy(() => import('pages/Dashboard/Customers/Users'));
const UserDetails = lazy(() => import('pages/Dashboard/Customers/Users/UserDetails'));
const EmailTemplate = lazy(() => import('pages/Dashboard/EmailTemplates'));
const AddMailTemplate = lazy(() => import('pages/Dashboard/EmailTemplates/AddTemplate'));
const UpdateMailTemplate = lazy(() => import('pages/Dashboard/EmailTemplates/UpdateTemplate'));
const Offers = lazy(() => import('pages/Dashboard/Rewards/Offers'));
const UserListing = lazy(() => import('pages/Dashboard/ManageRoles/UserListing'));
const UpdateUser = lazy(() => import('pages/Dashboard/ManageRoles/UserListing/UpdateUser'));
const AddUser = lazy(() => import('pages/Dashboard/ManageRoles/UserListing/AddUser'));
const Terms = lazy(() => import('pages/Dashboard/Terms'));
const UpdateOffers = lazy(() => import('pages/Dashboard/Rewards/Offers/UpdateOffers'));
const PasswordReset = lazy(() => import('pages/PasswordReset'));
const RedemptionCode = lazy(() => import('pages/Dashboard/Rewards/Offers/RedemptionCode'));
const UpdateOfferCode = lazy(() => import('pages/Dashboard/Rewards/Offers/RedemptionCode/UpdateOfferCode'));
const AddTermsCondition = lazy(() => import('pages/Dashboard/Terms/AddTermsCondition'));
const UpdateTermsCondition = lazy(() => import('pages/Dashboard/Terms/UpdateTermsCondition'));
const ManagePrivacyPolicy = lazy(() => import('pages/Dashboard/Privacy'));
const AddPrivacyPolicy = lazy(() => import('pages/Dashboard/Privacy/AddPrivacyPolicy'));
const UpdatePrivacyPolicy = lazy(() => import('pages/Dashboard/Privacy/UpdatePrivacyPolicy'));
const Permission = lazy(() => import('pages/Dashboard/ManageRoles/Permission'));
const RedemptionReports = lazy(() => import('pages/Dashboard/AdHocReports/index'));
const AdHocReports = lazy(() => import('pages/Dashboard/AdHocReports/AddAdHocReports'));
const RedemptionReportsDetails = lazy(() => import('pages/Dashboard/AdHocReports/RedemptionReportsDetails'));
const AdcRewardListing = lazy(() => import('pages/Dashboard/AdHocReports/AdcRewardListing'));
const AddRewardsAdHocReports = lazy(() => import('pages/Dashboard/AdHocReports/AddRewardsAdHocReports'));
const RewardsReportsDetails = lazy(() => import('pages/Dashboard/AdHocReports/RewardsReportsDetails'));
const Roles = lazy(() => import('pages/Dashboard/Roles'));
const UpdateRole = lazy(() => import('pages/Dashboard/Roles/UpdateRole'));
const AddRole = lazy(() => import('pages/Dashboard/Roles/AddRole'));
const Redemptions = lazy(() => import('pages/Dashboard/Redemptions'));
const AddFaq = lazy(() => import('pages/Dashboard/Faqs/AddFaq'));
const UpdateFaq = lazy(() => import('pages/Dashboard/Faqs/UpdateFaq'));
const Faqs = lazy(() => import('pages/Dashboard/Faqs'));
const CustomImages = lazy(() => import('pages/Dashboard/CustomImages'));
const AddCustomImage = lazy(() => import('pages/Dashboard/CustomImages/AddCustomImage'));
const UpdateCustomImage = lazy(() => import('pages/Dashboard/CustomImages/UpdateCustomImage'));
const UpdateCustomerUser = lazy(() => import('pages/Dashboard/Customers/Users/UpdateCustomerUser'));
const Survey = lazy(() => import('pages/Survey'));
const SurveyTermsConditions = lazy(() => import('pages/SurveyTermsCondition'));
const CricketSignup = lazy(() => import('pages/cricketClaim/Signup'));
const CricketSignin = lazy(() => import('pages/cricketClaim/Signin'));
const CricketRewardlisting = lazy(() => import('pages/cricketClaim/Rewardlisting'));
const CrickerProfile = lazy(() => import('pages/cricketClaim/Profile'));
const CricketRewardDetail = lazy(() => import('pages/cricketClaim/RewardDetail'));
const CricketCart = lazy(() => import('pages/cricketClaim/Cart'));
const CricketConfirmation = lazy(() => import('pages/cricketClaim/Confirmation'));
const CricketmyAccount = lazy(() => import('pages/cricketClaim/MyAccount'));
const CricketCodes = lazy(() => import('pages/Dashboard/CricketCodes'));
const CricketPasswordReset = lazy(() => import('pages/cricketClaim/CricketPasswordReset'));
const UpdatePasswordReset = lazy(() => import('pages/cricketClaim/UpdatePasswordReset'));

const publicRoutes = [
  {
    exact: true,
    path: PATH_NAME.ROOT,
    component: Home,
  },
];

const influencemobileRoutes = [
  {
    exact: true,
    path: PATH_NAME.ROOT,
    component: Home,
  },
  {
    exact: true,
    path: PATH_NAME.LOGIN,
    guard: GuestGuard,
    component: Login,
  },
  {
    exact: true,
    path: PATH_NAME.PASSWORD_RESET,
    guard: GuestGuard,
    component: PasswordReset,
  },

  {
    exact: true,
    path: PATH_NAME.REWARDS,
    component: RewardsList,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.REWARDSBYCATEGORY,
    component: RewardListsId,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.REWARD_DETAILS,
    component: RewardDetails,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.CART,
    component: Cart,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.CHECKOUT,
    component: Checkout,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.TERM_CONDITION,
    component: TermsCondition,
  },
  {
    exact: true,
    path: PATH_NAME.PRIVACY_POLICY,
    component: PrivacyPolicy,
  },
  {
    exact: true,
    path: PATH_NAME.FAQ,
    component: Faq,
  },
  {
    exact: true,
    path: PATH_NAME.Contact_Us,
    component: ContactUs,
  },
  {
    exact: true,
    path: PATH_NAME.PROFILE,
    component: Profile,
    guard: AuthGuard,
  },
];

const adminRoutes = [
  {
    exact: true,
    path: PATH_NAME.ADMIN_LOGIN,
    guard: GuestGuard,
    component: AdminLogin,
  },
  {
    exact: true,
    path: PATH_NAME.DASHBOARD,
    component: Dashboard,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_CUSTOMERS,
    component: customers,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CUSTOMER,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_CUSTOMER,
    component: AddCustomer,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CUSTOMER,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.CUSTOMERS_USER,
    component: CustomerUsers,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_DETAILS,
    component: UserDetails,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },

  {
    exact: true,
    path: PATH_NAME.UPDATE_CUSTOMER,
    component: UpdateCustomer,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CUSTOMER,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_CAMPAIGNS,
    component: Campaigns,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CAMPAIGN,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_CAMPAIGN,
    component: AddCampaign,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CAMPAIGN,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_CAMPAIGN,
    component: UpdateCampaign,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.CAMPAIGN,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.SET_REWARDS,
    component: SetRewards,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_REWARDS,
    component: Rewards,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.REWARD,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_REWARDS,
    component: UpdateRewards,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.REWARD,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.REDEMPTION_CODE,
    component: RedemptionCode,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_CODE,
    component: UpdateOfferCode,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },

  {
    exact: true,
    path: PATH_NAME.OFFERS,
    component: Offers,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.OFFER,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_OFFERS,
    component: UpdateOffers,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.OFFER,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },

  {
    exact: true,
    path: PATH_NAME.MANAGE_REWARD_CATEGORY,
    component: RewardCategory,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.REWARDCATEGORY,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_REWARD_CATEGORY,
    component: AddRewardCategory,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.REWARDCATEGORY,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_REWARD_CATEGORY,
    component: UpdateRewardCategory,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.REWARDCATEGORY,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_MAIL_TEMPLATES,
    component: EmailTemplate,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.EMAIL,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_MAIL_TEMPLATES,
    component: AddMailTemplate,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.EMAIL,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_MAIL_TEMPLATES,
    component: UpdateMailTemplate,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.EMAIL,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_LISTING,
    component: UserListing,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.USER,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_UPDATE,
    component: UpdateCustomerUser,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.USER,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },

  {
    exact: true,
    path: PATH_NAME.UPDATE_USER,
    component: UpdateUser,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.USER,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_USER,
    component: AddUser,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.USER,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.TERMS,
    component: Terms,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.TERMS,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_TERMS,
    component: AddTermsCondition,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.TERMS,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_TERMS,
    component: UpdateTermsCondition,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.TERMS,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_PRIVACY_POLICY,
    component: ManagePrivacyPolicy,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PRIVACY,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_PRIVACY_POLICY,
    component: AddPrivacyPolicy,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PRIVACY,
    permissionType: PERMISSION_TYPE.ADD,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.UPDATE_PRIVACY_POLICY,
    component: UpdatePrivacyPolicy,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PRIVACY,
    permissionType: PERMISSION_TYPE.EDIT,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.MANAGE_PERMISSION,
    component: Permission,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADHOC_REPORT,
    component: RedemptionReports,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_ADHOC_REPORT,
    component: AdHocReports,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.REDEMPTION_VIEW_REPORT,
    component: RedemptionReportsDetails,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADHOC_REWARDS_REPORT,
    component: AdcRewardListing,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.ADD_ADHOC_REWARDS_REPORT,
    component: AddRewardsAdHocReports,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.REWARDS_VIEW_REPORT,
    component: RewardsReportsDetails,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_ROLES,
    component: Roles,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_ROLES_UPDATE,
    component: UpdateRole,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.USER_ROLES_ADD,
    component: AddRole,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.REDEMPTION_HISTORY,
    component: Redemptions,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.FAQS,
    component: Faqs,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.FAQS_ADD,
    component: AddFaq,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.FAQS_UPDATE,
    component: UpdateFaq,
    guard: AdminAccess,
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.CUSTOM_IMAGES,
    component: CustomImages,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.CUSTOM_IMAGES_ADD,
    component: AddCustomImage,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
    permissionKey: PERMISSION_MODULE.PERMISSION,
    permissionType: PERMISSION_TYPE.LISTING,
  },
  {
    exact: true,
    path: PATH_NAME.CUSTOM_IMAGES_UPDATE,
    component: UpdateCustomImage,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_REGISTRATION_CODES,
    component: CricketCodes,
    guard: AdminAccess,
    requireRoles: [USER_ROLE.ADMIN],
  },
];

const surveyRoutes = [
  {
    exact: true,
    path: PATH_NAME.SURVEY_TERM_CONDITION,
    component: SurveyTermsConditions,
  },
  {
    exact: true,
    path: PATH_NAME.SURVEY,
    component: Survey,
  },
  {
    exact: true,
    path: PATH_NAME.ROOT,
    component: Survey,
  },
];

const cricketRoutes = [
  {
    exact: true,
    path: PATH_NAME.ROOT,
    component: CricketSignup,
    guard: GuestGuard,
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_SIGNUP,
    guard: GuestGuard,
    component: CricketSignup,
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_SIGNIN,
    guard: GuestGuard,
    component: CricketSignin,
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_PASSWORD_RESET,
    guard: GuestGuard,
    component: CricketPasswordReset,
  },
  {
    exact: true,
    path: PATH_NAME.PASSWORD_RESET,
    guard: GuestGuard,
    component: UpdatePasswordReset,
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_REWARDLIST,
    component: CricketRewardlisting,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.CRICKET_REWARD_DETAILS,
    component: CricketRewardDetail,
    guard: AuthGuard,
  },

  {
    exact: true,
    path: PATH_NAME.CRICKET_CART,
    component: CricketCart,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.CHECKOUT,
    component: CricketConfirmation,
    guard: AuthGuard,
  },
  {
    exact: true,
    path: PATH_NAME.PROFILE,
    component: CricketmyAccount,
    guard: AuthGuard,
  },
];

export { publicRoutes, influencemobileRoutes, adminRoutes, cricketRoutes, surveyRoutes };
