import { PATH_NAME } from 'configs/pathName';
import { extractSubdomain } from 'helpers/comman';
import { Redirect } from 'react-router';
import { cricket } from 'routes/subdomains';

// configs

// services
import authService from 'services/authService';

const GuestGuard = ({ children }: any) => {
  const isAuth = authService.getAccessToken();
  const domain = extractSubdomain();
  if (isAuth) return <Redirect to={domain === cricket ? PATH_NAME.CRICKET_REWARDLIST : PATH_NAME.ROOT} />;

  return <>{children}</>;
};

export default GuestGuard;
