import React, { useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import Routes from './routes/Routes';
import mixpanel from 'mixpanel-browser';
import { ToastContainer } from 'react-toastify';

import Footer from 'components/Footer';
import { connect, useDispatch } from 'react-redux'; // Removed unnecessary import
import { USER_ROLE } from 'configs/userRole';

import CartList from 'components/CartList';
import GenericLoader from 'components/GenericLoader';
import 'sweetalert2/src/sweetalert2.scss';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { sessionExpired } from 'redux/actions/auth.action';
import CompleteProfileModal from 'components/Modals/CompleteProfileModal';
import LoginModal from 'components/Modals/LoginModal';
import './App.scss';
import { extractSubdomain } from 'helpers/comman';
import CustomFooter from 'components/CustomFooter';
import { cricket, diageo, influencemobile } from 'routes/subdomains';

const App = ({ state, isLoading, isSurvey }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID;
  useEffect(() => {
    mixpanel.init(MIXPANEL_ID, { debug: true });
  }, []);

  const subdomain = extractSubdomain();

  // Conditionally select footer based on subdomain
  const renderFooter = () => {
    if (state.role === USER_ROLE.ADMIN || isSurvey) return null;
    switch (subdomain) {
      case influencemobile:
        return <CustomFooter type={influencemobile} />;
      case cricket:
        return <CustomFooter type={cricket} />;
      case diageo:
        return null;
      default:
        return null;
    }
  };

  try {
    return (
      <Router basename="/">
        <Routes />
        <ToastContainer position="top-center" />
        <CompleteProfileModal />
        <LoginModal />
        <CartList />
        {renderFooter()}
        <GenericLoader open={isLoading} />
      </Router>
    );
  } catch (error) {
    dispatch(sessionExpired());
  }
};

const mapStateToProps = (state: any) => ({
  state: state.auth,
  isLoading: state.app.isLoading,
  isSurvey: state.app.isSurvey,
});

export default connect(mapStateToProps)(App);
