import { Fragment, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { PATH_NAME } from 'configs/pathName';
import { IRoutes } from '../models/IRoutes';
import NavLayout, { EmptyNavLayout } from 'layouts/NavLayouts/NavLayout';
import RoleRoute from './RoleRoute';
import HomeSkeleton from 'layouts/SkeletonLoader/SkeletonLoader';
import AdminLayoutDecider from 'layouts/AdminLayoutDecider/AdminLayoutDecider';
import PermissionAccess from 'guards/AccessGuard/PermissionAccess';
import { adminRoutes, cricketRoutes, influencemobileRoutes, publicRoutes, surveyRoutes } from './RoutesList';
import { extractSubdomain } from 'helpers/comman';
import { cricket, diageo, influencemobile } from './subdomains';

// Import Users routes
const Home = lazy(() => import('pages/Home/Home'));
const CommingSoon = lazy(() => import('components/CommingSoon/CommingSoon'));

const routesConfig: IRoutes[] = [
  {
    path: PATH_NAME.ROOT,
    layout: process.env.REACT_APP_SHOW_HOME ? NavLayout : EmptyNavLayout,
    routes: process.env.REACT_APP_SHOW_HOME
      ? [
          // Load routes based on the subdomain
          ...(() => {
            const subdomain = extractSubdomain();
            switch (subdomain) {
              case influencemobile:
                return [...influencemobileRoutes, ...publicRoutes, ...adminRoutes];
              case cricket:
                return [...cricketRoutes, ...publicRoutes, ...adminRoutes];
              case diageo:
                return [...surveyRoutes, ...publicRoutes, ...adminRoutes];
              default:
                return [...publicRoutes, ...adminRoutes]; // No specific routes for other subdomains
            }
          })(),
          // Add common routes if needed
        ]
      : [
          {
            path: '*',
            component: CommingSoon,
          },
        ],
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: Home,
      },
      {
        component: () => <Redirect to={PATH_NAME.ROOT} />,
      },
    ],
  },
];

const renderRoutes = (routes: IRoutes[]) => {
  return (
    <>
      {routes ? (
        <Suspense fallback={<HomeSkeleton />}>
          <Switch>
            {routes.map((route: IRoutes, idx: number) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const permissionKey = route.permissionKey || null;
              const permissionType = route.permissionType || null;

              const Component = route.component;
              const requireRoles = route.requireRoles || [];

              return (
                <Route
                  key={`routes-${idx}`}
                  path={route.path}
                  exact={route.exact}
                  render={(props: any) => (
                    <Guard>
                      <PermissionAccess permissionType={permissionType} permissionKey={permissionKey}>
                        <Layout>
                          {route.routes ? (
                            renderRoutes(route.routes)
                          ) : (
                            <RoleRoute requireRoles={requireRoles}>
                              <Component {...props} />
                            </RoleRoute>
                          )}
                        </Layout>
                      </PermissionAccess>
                    </Guard>
                  )}
                />
              );
            })}
          </Switch>
        </Suspense>
      ) : null}
    </>
  );
};

function Routes() {
  return (
    <>
      <AdminLayoutDecider>{renderRoutes(routesConfig)}</AdminLayoutDecider>
    </>
  );
}

export default Routes;
