import axios, { AxiosRequestConfig, AxiosError } from 'axios';

export type IConfig = AxiosRequestConfig & {
  showSpinner?: boolean;
};

type IAxiosResponse = AxiosError & {
  config: {
    showSpinner?: boolean;
  };
};

function getAccessToken() {
  const accessToken = window.localStorage.getItem('accessToken');
  return accessToken;
}

const requestConfig: IConfig = {
  baseURL: process.env.REACT_APP_ENDPOINT_URL,
  timeout: 50000,
  showSpinner: false,
};

export const axiosInstance = axios.create(requestConfig);

export default function initRequest(store: any) {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      // add x-auth-token
      const accessToken = getAccessToken();
      if (accessToken) {
        // config.headers['x-auth-token'] = accessToken;
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error: IAxiosResponse) => {
      return Promise.reject(error);
    },
  );
}
