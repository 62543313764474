import React, { FC, useEffect, useState } from 'react';

// material core
import CssBaseline from '@mui/material/CssBaseline';
import ErrorBoundary from 'containers/ErrorBoundary';
import { Button } from '@mui/material';
import MainNavbar from './NavBar';
import { useSelector } from 'react-redux';
import { roleSelector } from 'selectors/auth.selector';
import CricketNavBar from './CricketNavBar';
import { extractSubdomain } from 'helpers/comman';
import { USER_ROLE } from 'configs/userRole';
import { cricket, diageo, influencemobile } from 'routes/subdomains';

const NavLayout: FC = ({ children }: any) => {
  const userRole = useSelector((state: any) => state.auth.role);
  const isSurvey = useSelector((state: any) => state.app.isSurvey);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const subdomain = extractSubdomain();

  const token = !!localStorage.getItem('accessToken');
  useEffect(() => {
    setIsLoggedIn(token);
  }, [token]);

  const renderFooter = () => {
    if (userRole?.role === USER_ROLE.ADMIN || isSurvey) return null;
    switch (subdomain) {
      case influencemobile:
        return <MainNavbar isLoggedIn={isLoggedIn} />;
      case cricket:
        return <CricketNavBar isLoggedIn={isLoggedIn} />;
      case diageo:
        return null;
      default:
        return null;
    }
  };

  return (
    <div>
      <CssBaseline />
      {renderFooter()}
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  );
};

export const EmptyNavLayout: FC = ({ children }: any) => {
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default NavLayout;
