import React from 'react';
import { Drawer, Button, IconButton } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { handleCartList, redeemRewards, removeFromCart } from 'redux/actions/auth.action';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IMAGES_PATH } from 'configs/imagesPath';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mixpanelTrack } from 'helpers/mixpanelTracking';
import { extractSubdomain } from 'helpers/comman';
import { cricket } from 'routes/subdomains';

function CartDrawer({ isCartOpen, cartItems = [], customerCredits, cartValue = 0, remainingBalance, user, state }: any) {
  const dispatch = useDispatch();
  const history = useHistory();
  const subdomain = extractSubdomain();

  const handleRedeemReward = async () => {
    mixpanelTrack('cart_checkout');
    const body = {
      rewards: cartItems,
      customeruser: user.user.id,
      campaign: null,
      customer: user.user.customer_id,
      date_redeemed: new Date(),
    };
    dispatch(redeemRewards(body, history));
  };

  return (
    <Drawer
      className="cart-wrapper"
      anchor="right"
      open={isCartOpen}
      onClose={() => dispatch(handleCartList(false))}
      aria-labelledby="cart-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="content">
        <div className="cart-header">
          <h2 id="cart-title" className="title">
            Cart
          </h2>
          <IconButton
            aria-label="Close cart slider"
            aria-hidden={false}
            aria-describedby="cartCloseDescription"
            role="button"
            onClick={() => dispatch(handleCartList(false))}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="cart-body">
          {cartItems.length > 0 ? (
            cartItems.map((item: any, index: number) => (
              <div className="item-list" key={index}>
                <div className="d-flex align-items-center col-11">
                  <div className="reward-logo">
                    <div className="logo-wrap">
                      <img src={item.logoUrl || IMAGES_PATH.logo.logo} alt={`${item.reward_name} logo`} className="img-fluid" />
                    </div>
                  </div>
                  <div className="reward-intro">
                    <div className="rew-title">{item.reward_name}</div>
                    {item.description && (
                      <div
                        className="rew-desc"
                        dangerouslySetInnerHTML={{
                          __html: item.description.length > 20 ? item.description.slice(0, 50) + '...' : item.description,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="action d-flex align-items-center flex-column col-1">
                  <div className="price" aria-label={`Quantity: 1`}>
                    1
                  </div>
                  <div className="delete mt-auto ms-lg-auto">
                    <IconButton
                      aria-label={`Delete ${item.reward_name} from cart`}
                      onClick={() => dispatch(removeFromCart(item.unique_id))}
                      aria-hidden={false}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p role="alert">Your cart is empty.</p>
          )}
        </div>
        <div className="cart-footer">
          {subdomain !== cricket && (
            <>
              <div className="d-flex justify-content-between align-content-center">
                <div className="label">Total Credit</div>
                <div className="total-price" aria-label={`Total credit: ${cartValue}`}>
                  {cartValue}
                </div>
              </div>
              <div className="d-flex justify-content-between align-content-center">
                <div className="label">Remaining Credit</div>
                <div className="total-price" aria-label={`Remaining credit: ${remainingBalance}`}>
                  {remainingBalance}
                </div>
              </div>
            </>
          )}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={subdomain === cricket ? 'cricket-button' : 'pinkbutton'}
            disabled={cartItems.length === 0}
            onClick={handleRedeemReward}
            aria-disabled={cartItems.length === 0}
            aria-label="Checkout and redeem rewards"
          >
            Checkout
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

const mapStateToProps = (state: any) => ({
  isCartOpen: state.auth.isCartOpen,
  cartItems: state.auth.cartItems,
  customerCredits: state.auth.customerCredits,
  cartValue: state.auth.cartValue,
  remainingBalance: state.auth.remainingBalance,
  user: state.auth.user,
  state: state,
});

export default connect(mapStateToProps)(CartDrawer);
