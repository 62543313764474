import api from 'apis/api';
import { getAllCampaignRewards, getAllLanguage, getAllOrders, getCustomerRewardCategory } from 'apis/common.api';
import AlertEmpty from 'components/Modals/AlertEmpty';
import { PATH_NAME } from 'configs/pathName';
import { IAppActionTypes, INotifer } from 'models/IAppState';
import { IAuthActionTypes } from 'models/IAuthState';
import { IHistory } from 'models/ICommon';
import { Dispatch } from 'redux';
import httpRequest from 'services/httpRequest';
import Swal from 'sweetalert2';

export const setLoading = (isLoading: boolean) => ({
  type: IAppActionTypes.SET_LOADING,
  payload: isLoading,
});
export const setActiveCustomerInReward = (customer: any, page: any) => ({
  type: IAppActionTypes.SET_ACTIVE_CUSTOMER_IN_REWARDS,
  payload: { customer: customer, page: page },
});

export const hideHeaderFoooter = (isSurvey: boolean) => ({
  type: IAppActionTypes.SET_ISSURVEY_SUCCESS,
  payload: { isSurvey: isSurvey },
});

export const setRewardsList =
  (campaign: any, page: any, history: IHistory, category: any, limit = 9) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: IAppActionTypes.SET_REWARDS_LIST_REQUEST });
    const body = {
      campaign,
      skip: page,
      limit: limit,
      category: category,
      category_name: category,
    };
    try {
      //    const response = await api.post('/customers/get_campaign_rewards', body);
      const response = await api.post('/rewards/get_category_rewards', body);
      if (response.data.status) {
        dispatch({
          type: IAppActionTypes.SET_REWARDS_LIST_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: IAppActionTypes.SET_REWARDS_LIST_FAILURE,
        });
        AlertEmpty({ title: 'Cannot fetch rewards', message: response.data.message, button: 'Okay' });
        history.push(PATH_NAME.ROOT);
      }
    } catch (error) {
      console.error('Error while fetching rewards:', error);
      dispatch({
        type: IAppActionTypes.SET_REWARDS_LIST_FAILURE,
      });
      AlertEmpty({
        title: 'Cannot fetch rewards',
        message: 'An error occurred while fetching rewards. Please try again later.',
        button: 'Okay',
      });
      history.push(PATH_NAME.ROOT);
    }
  };

export const setRewardsCategoryList = (id: any, campaign: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAppActionTypes.SET_REWARD_CATEGORIES_REQUEST });

  try {
    const response = (await getCustomerRewardCategory(id, campaign)) as any;
    if (response.data.status) {
      dispatch({
        type: IAppActionTypes.SET_REWARD_CATEGORIES_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: IAppActionTypes.SET_REWARDS_LIST_FAILURE,
      });
      AlertEmpty({
        title: response.data.message,
        message: `Failed to fetch rewards for category. Please try again later.`,
        button: 'Okay',
      });
      history.push(PATH_NAME.ROOT);
    }
  } catch (error) {
    console.error('Error while fetching rewards:', error);
    dispatch({
      type: IAppActionTypes.SET_REWARD_CATEGORIES_FAILURE,
    });
    AlertEmpty({
      title: 'Cannot fetch rewards',
      message: 'An error occurred while fetching rewards. Please try again later.',
      button: 'Okay',
    });
    history.push(PATH_NAME.ROOT);
  }
};

export const setRedeemedRewardsList = (id: any, history: IHistory) => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAppActionTypes.SET_REDEEMED_REWARDS_REQUEST });

  try {
    const response = (await getAllOrders(id)) as any;
    if (response.data.status) {
      dispatch({
        type: IAppActionTypes.SET_REDEEMED_REWARDS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: IAppActionTypes.SET_REDEEMED_REWARDS_FAILURE,
      });
      AlertEmpty({
        title: 'Cannot fetch redeemed rewards',
        message: `Failed to fetch redeemed rewards. Please try again later.`,
        button: 'Okay',
      });
      history.push(PATH_NAME.ROOT);
    }
  } catch (error) {
    console.error('Error while fetching redeemed rewards:', error);
    dispatch({
      type: IAppActionTypes.SET_REDEEMED_REWARDS_FAILURE,
    });
    AlertEmpty({
      title: 'Cannot fetch redeemed rewards',
      message: 'An error occurred while fetching redeemed rewards. Please try again later.',
      button: 'Okay',
    });
    history.push(PATH_NAME.ROOT);
  }
};

export const setAllLanguage = () => async (dispatch: Dispatch<any>) => {
  dispatch({ type: IAppActionTypes.SET_LANGUAGE_REQUEST });

  try {
    const response = (await getAllLanguage()) as any;
    if (response.status === 200) {
      dispatch({
        type: IAppActionTypes.SET_LANGUAGE_SUCCESS,
        payload: response.data,
      });
    } else {
      dispatch({
        type: IAppActionTypes.SET_LANGUAGE_FAILURE,
      });
      AlertEmpty({
        title: 'Cannot fetch language!',
        message: `Failed to fetch language. Please try again later.`,
        button: 'Okay',
      });
    }
  } catch (error) {
    console.error('Error while language rewards:', error);
    dispatch({
      type: IAppActionTypes.SET_REDEEMED_REWARDS_FAILURE,
    });
    AlertEmpty({
      title: 'Cannot fetch language rewards',
      message: 'An error occurred while fetching language. Please try again later.',
      button: 'Okay',
    });
  }
};
