import { IAuthActionTypes, IAuthActionCreator, IAuthState, INewUserActionTypes } from 'models/IAuthState';

const initialState: IAuthState = {
  isLoading: false,
  isSigningIn: false,
  isCreateUserLoading: false,
  isResetPasswordLoading: false,
  isUpdatePasswordLoading: false,
  user: null,
  role: null,
  isLoggedIn: false,
  code: '',
  isAdmin: {},
  is_new: false,
  customer_unique_id: '',
  isCartOpen: false,
  cartItems: [],
  customerCredits: 0, // Total credits available to the customer
  creditsUsed: 0, // Total credits used in the cart
  remainingBalance: 0, // Remaining balance after adding items to the cart
  campaign: {},
  customer: {},
  cartValue: 0,
  ishaveBalance: true,
  loginModal: false,
};

const validValue = (key: string, propVal: any, stateVal: any) => {
  return propVal![key] ? propVal[key] : stateVal?.member![key];
};
const handleUserDataState = (payloadData: any, stateData: any) => {
  const result: any = {};
  [
    'email',
    'firstName',
    'lastName',
    'password',
    'confirm_password',
    'birthday',
    'address1',
    'address2',
    'country',
    'zipcode',
    'phone',
  ].map((eachKey) => {
    result[eachKey] = validValue(eachKey, payloadData, stateData);
  });

  return {
    ...stateData.member,
    ...payloadData,
    ...result,
  };
};

const reducer = (state = initialState, { type, payload }: IAuthActionCreator) => {
  switch (type) {
    case IAuthActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        isLoading: true,
      };
    case IAuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload.user,
        role: payload.role,
        code: payload.code,
        isSigningIn: false,
        isLoading: false,

        // isLoggedIn: true,
      };
    case IAuthActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isSigningIn: false,
        isLoading: false,
      };

    case IAuthActionTypes.LOGIN_CUSTOMER_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        isLoading: true,
      };
    case IAuthActionTypes.LOGIN_CUSTOMER_SUCCESS:
      const { user } = payload;
      return {
        ...state,
        user: user,
        customer_unique_id: user.customer_unique_id,
        isSigningIn: false,
        customerCredits: user.user.creditBalance,
        remainingBalance: user.user.creditBalance,
        creditsUsed: user.user.creditsUsed,
        campaign: user.campaign,
        customer: user.customer,
        isLoggedIn: true,
        is_new: user.is_new,
        loginModal: false,
        isLoading: false,
      };
    case IAuthActionTypes.LOGIN_CUSTOMER_FAILURE:
      return {
        ...state,
        user: null,
        isSigningIn: false,
        isLoading: false,
      };

    case IAuthActionTypes.OPEN_SIGNUP_MODAL:
      return {
        ...state,
        isLoggedIn: payload.open,
      };

    case IAuthActionTypes.OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModal: payload.open,
      };

    case IAuthActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResetPasswordLoading: true,
      };
    case IAuthActionTypes.RESET_PASSWORD:
      return {
        ...state,
        isResetPasswordLoading: false,
      };
    case IAuthActionTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isUpdatePasswordLoading: true,
      };
    case IAuthActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        isUpdatePasswordLoading: false,
      };
    case IAuthActionTypes.LOGOUT:
      return { ...initialState };
    case IAuthActionTypes.SILENT_LOGIN:
      return {
        ...state,
        user: payload.user,
        role: payload.role,
        code: payload.code,
      };
    case IAuthActionTypes.SET_CART_OPEN: {
      return {
        ...state,
        isCartOpen: payload.open,
      };
    }

    case IAuthActionTypes.TWOFA_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        twofaRequest: true,
        isAdmin: payload.admin,
      };
    case IAuthActionTypes.TWOFA_SUCCESS:
      return {
        ...state,
        user: payload.user,
        role: payload.role,
        code: payload.code,
        isSigningIn: false,
        twofaRequest: false,
        isAdmin: {},
      };
    case IAuthActionTypes.TWOFA_FAILURE:
      return {
        ...state,
        user: null,
        isSigningIn: true,
        twofaRequest: true,
        isAdmin: {},
      };
    case IAuthActionTypes.TWOFA_CLOSE:
      return {
        ...state,
        user: null,
        isSigningIn: false,
        twofaRequest: false,
        isAdmin: {},
      };
    case IAuthActionTypes.RESEND_TWOFA_REQUEST:
      return {
        ...state,
        resendTwoFa: payload.sent,
        isSigningIn: true,
        twofaRequest: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_SUCSESS:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: true,
      };
    case IAuthActionTypes.RESEND_TWOFA_FAILURE:
      return {
        ...state,
        twofaRequest: true,
        isSigningIn: true,
        resendTwoFa: false,
      };

    case INewUserActionTypes.SET_USER_PROFILE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case INewUserActionTypes.SET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        customerCredits: payload.creditBalance,
        remainingBalance: payload.creditBalance,
        creditsUsed: payload.creditsUsed,
        isLoggedIn: false,
        is_new: payload.is_new,
        isLoading: false,
        user: { ...state.user, user: payload },
      };
    }
    case INewUserActionTypes.SET_USER_PROFILE_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerCredits: payload.data.creditBalance,
        user: { ...state.user, user: payload.data },
      };

    case IAuthActionTypes.SET_REQUEST_PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART_SUCCESS:
      const itemCredits = payload.credit; // Replace 'amount' with your item's credit property
      if (itemCredits > state.customerCredits) {
        return {
          ...state,
          isLoading: false,
          ishaveBalance: false,
          errorMessage: 'Not enough credits to add this item to the cart.',
        };
      }
      const newCartValue = state.cartValue + itemCredits;
      return {
        ...state,
        isLoading: false,
        isCartOpen: true,
        cartItems: [...state.cartItems, { ...payload }],
        cartValue: newCartValue,
        ishaveBalance: true,
        remainingBalance: state.customerCredits - newCartValue,
        errorMessage: null,
      };

    case IAuthActionTypes.SET_REQUEST_ADD_TO_CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload, // Store the error message
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT_SUCCESS:
      return {
        ...state,
        isCartOpen: false,
        cartItems: [],
        customerCredits: payload.user.creditBalance, // Total credits available to the customer
        creditsUsed: 0, // Total credits used in the cart
        cartValue: 0,
        remainingBalance: payload.user.creditBalance, // Remaining balance after adding items to the cart
        isLoading: false,
      };

    case IAuthActionTypes.SET_REQUEST_CHECKOUT_FAILURE:
      const resourceIdsToRemove = payload.map((item: any) => item.rid);
      const updatedCartItems = state.cartItems.filter((item: any) => !resourceIdsToRemove.includes(item.reward));

      // Calculate the new values for creditsUsed, cartValue, and remainingBalance
      const newCreditsUsed = updatedCartItems.reduce((total: any, item: any) => total + item.credit, 0);
      const cartValue = updatedCartItems.reduce((total: any, item: any) => total + item.credit, 0);
      const newRemainingBalance = state.customerCredits - newCreditsUsed;
      return {
        ...state,
        isLoading: false,
        cartItems: updatedCartItems,
        creditsUsed: newCreditsUsed, // Total credits used in the cart
        cartValue: cartValue, // Total cart value
        remainingBalance: newRemainingBalance, // Remaining balance after adding items to the cart
      };

    case IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART:
      return {
        ...state,
        isLoading: true,
      };

    case IAuthActionTypes.SET_REQUEST_REMOVE_FROM_CART_SUCCESS:
      const removedItem = state.cartItems.find((item: any) => item.unique_id === payload);
      if (removedItem) {
        return {
          ...state,
          isLoading: false,
          cartItems: state.cartItems.filter((item: any) => item.unique_id !== payload),
          cartValue: state.cartValue - removedItem.credit,
          remainingBalance: state.customerCredits - state.cartValue + removedItem.credit,
          errorMessage: null,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessage: 'Item not found in the cart.',
        };
      }

    case IAuthActionTypes.SET_REQUEST_CLEAR_CART_SUCCESS:
      return {
        ...state,
        cartItems: [], // Clear the cart by resetting cartItems to an empty array
        creditsUsed: 0,
      };

    default:
      return state;
  }
};

export default reducer;
